<script setup>
import {onMounted, ref} from "vue";
import {DBService, post} from "../../services/apiReq2";
import {Input} from 'digitalpower';
import {show} from "../../services/notification";

const nombre = ref("");
const apellido = ref("");
const email = ref("");
const telefono = ref("");
const dni = ref("");

const fetching = ref(false);

onMounted(() => {
  localStorage.setItem("user", JSON.stringify( {
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6Ik1hcnRpbiIsInN1cm5hbWUiOiJDYXRhbGFubyIsImVtYWlsIjoiY2F0YWxhbm9yZXNpbWFydGluQGdtYWlsLmNvbSIsInBob25lIjoiMTIzIiwiZG5pIjoiMTIzIiwicGFzc3dvcmQiOiIkMmIkMTAkdnNmQ1Z3RHlrNkFncURhZEo3R0k5T1Z5Sy4uQlpXamhCdFJJTGROdG1Wa3hLN2lIS0dncmkiLCJyb2xlIjoic3VwZXItYWRtaW4iLCJzaXRlX25hbWUiOiJwYXBlbGVyYS5kYiIsInBhbmVsX2FkbWluIjoxLCJpYXQiOjE3MzcyMzcxNzl9.mgf0uSAvHfE5FnHVcuPiULkae-mb81LPIjwAiAUWd2g'
  }));
})

async function save() {
  fetching.value = true;
  if (!nombre.value || !apellido.value || !email.value || !telefono.value || !dni.value) {
    show("Por favor, complete todos los campos.");
    fetching.value = false;
    return;
  }

  if (dni.value.length < 8) {
    show("El DNI debe tener exactamente 8 caracteres.");
    return;

  }
  let response = await post('/insert', {
    "table": "formularios_sorteo",
    "data": {
      "nombre": nombre.value,
      "apellido": apellido.value,
      "email": email.value,
      "telefono": telefono.value,
      "dni": dni.value,
    }
  }, DBService);

  if (response.data?.message == 'Dato guardado') {
    location.href = `/formulario/comprobante?sorteo=1&id=${dni.value}`;
  }
  fetching.value = false;
}

</script>

<template>
  <div class="container">
    <div class="content">
      <div class="form">
        <h3>Superaste la compra de $ 100.000, completa el formulario para participar por un viaje a Río de Janeiro</h3>
        <Input class="input" label="Nombre" placeholder="Nombre" v-model="nombre" />
        <Input class="input" label="Apellido" placeholder="Apellido" v-model="apellido" />
        <Input type="email" class="input" label="Email" placeholder="Email" v-model="email" />
        <Input class="input" label="Telefono" placeholder="Telefono" v-model="telefono" />
        <Input type="number" class="input" label="DNI" placeholder="12..." v-model="dni" />
        <div class="button-container">
          <Input
              type="button"
              value="Enviar"
              background="orangered"
              color="white"
              class="submit-button"
              @click="save"
              v-if="!fetching"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

h3 {
  font-size: 1.5em;
  padding-top: 5em;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 90%;
  max-width: 600px;
  background: #fff;
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
}

.input {
  width: calc(50% - 8px);
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submit-button {
  width: auto;
  padding: 0.75em 1.5em;
  text-align: center;
  background-color: orangered;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.submit-button:hover {
  background-color: darkred;
}
</style>
