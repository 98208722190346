<script setup>
import {ref, onMounted, onBeforeUnmount} from 'vue';
import {Image, Carousel, CarouselItem} from 'digitalpower';

const path = '/images/Banners';

const images = [
  'SanValentin',
  'Fortaleza',
  'Stock',
  'Harina',
  'Fecula',
];

function getImage(resolution, image) {
  return `${path}/${image}/${resolution}.svg??`;
}

</script>

<template>
<div class="hero">
  <Carousel id="hero-carousel" interval="3000" blackArrows>
    <CarouselItem v-for="(image, key) in images" :key :active="key === 0">
      <Image :mobile="getImage('mobile', image)" :tablet="getImage('tablet', image)" :pc="getImage('pc', image)"
             :pclarge="getImage('pc-large', image)"/>
    </CarouselItem>
  </Carousel>
</div>
</template>

<style scoped>
.hero {
  height: 400px;
}
img {
  width: 100%;
}
</style>

