<script setup>
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import {DBService, post} from "../../../services/apiReq2";
import {formatNumber, getDate} from "@/utils/utils";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const route = useRoute();
const {sorteo, id} = route.query;

const sorteoData = ref({});

onMounted(async () => {
  let response = await post('/read', {
    table: `formularios_sorteo${(sorteo == 1) ? '' : sorteo}`,
    page: 1,
    limit: 1,
    where: [
      {condition: {dni: id}}
    ]
  }, DBService);
  sorteoData.value = response.data?.rows?.[0];

  setTimeout(async () => {
    const elemento = document.getElementById("comprobante");
    const canvas = await html2canvas(elemento);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width en mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Mantener proporción

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save("comprobante.pdf");
  }, 2000)
})

function genId () {
  return formatNumber(sorteoData.value?.id);
}
</script>

<template>
<div id="comprobante-container" flex flex-center>
  <div id="comprobante" class="comprobante" radius-border flex>
    <div class="left">
      <div class="top text-center" flex flex-center><b>TICKET <br> SORTEO</b></div>
      <div class="bottom" flex flex-column flex-center>
        <b class="text-center" id="codigo">Codigo de compra: #{{genId()}}</b>
        <b class="text-center">Fecha: {{getDate()}}</b>
        <i class="text-center mt-3">Por favor conserve el siguiente ticket. Valido solo para el sorteo n°{{sorteo}}</i>
      </div>
    </div>
    <div class="right">
      <div class="top" flex flex-center>
        <h2>La Fortaleza</h2>
      </div>
      <div class="bottom" flex flex-center flex-column>
        <h3>SORTEO N°{{sorteo}}</h3>
        <b>{{ sorteoData?.nombre }} {{ sorteoData?.apellido }}</b>
        <b>{{sorteoData?.dni}}</b>
        <p>#{{genId()}}</p>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
#comprobante-container {
  height: 100vh;
  padding-top: 7em;
}

#codigo {
  width: 90%;
}

.comprobante {
  width: 800px;
  height: 300px;

  .left, .right {
    height: 100%;
  }

  .left {
    width: 30%;
    border-right-style: dashed !important;
    border-right: 2px solid #373737;
  }

  .right {
    width: 70%;
  }
}

.top, .bottom {
  width: 100%;
}

.top {
  height: 20%;
  color: white;
  background: orangered;
}

.bottom {
  height: 80%;

  background: beige;
}

</style>