export function specialDate() {
    const today = new Date();
    const month = today.getMonth();

    let special = null;
    let specialColor = "";

    if (month === 11) {
        special = { name: "christmas", text: "NAVIDAD", icon: 'snow-outline' };
    }
    if (month === 0 || month === 1) {
        special = { name: "valentines", text: "SAN VALENTIN", icon: '' }
    }
    else if (month === 9) {
        special = { name: "halloween", text: "HALLOWEEN", icon: '' }
    }
    /*else if (month === 1) {
        special = { name: "valentines", text: "SAN VALENTIN", icon: '' }
    }*/
    else if (month === 4) {
        special = { name: "pascuas", text: "PASCUAS", icon: '' }
    }

    if (special) {
        document.documentElement.style.setProperty('--special-color', `var(--${special.name})`);
    }
    return special;
}

export function formatNumber(n) {
    let str = String(n);
    return str.padStart(4, '0');
}

export function getDate () {
    const fecha = new Date()
    const dia = String(fecha.getDate()).padStart(2, '0')
    const mes = String(fecha.getMonth() + 1).padStart(2, '0')
    const anio = fecha.getFullYear()
    return `${dia}/${mes}/${anio}`;
}